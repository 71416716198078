<template>
  <div class="data">
    <v-header />
    <div style="height: 70px;"></div>
    <div class="tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>投标文件</el-breadcrumb-item>
        <el-breadcrumb-item>文件详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="news">
      <div class="news-left">
        <div class="datas">
          <h1>厂房物业服务投标方案（2023修订版）</h1>
          <div class="box">
            <div class="list">作者：觅模网</div>
            <div class="list">浏览量：2293</div>
            <div class="list">发布日期：2023-09-13</div>
          </div>
          <div class="btn">
             <el-button type="primary">下载文档</el-button>
          </div>
        </div>
        <div class="content">
          <div class="con">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="文章简介" name="first">文章简介</el-tab-pane>
              <el-tab-pane label="目录预览" name="second">目录预览</el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <div class="news-right">
        <h3>热门资讯</h3>
        <ul>
          <li v-for="(list,index) in 10" :key="index">
            <p
              :class="index == 0 ? 'code-1' : index == 1 ? 'code-2' : index == 2 ? 'code-3' : 'code'"
              class="code"
            >{{index + 1}}</p>
            <a href>车辆维修服务投标方案（技术标 382页）</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import header from "@/components/header";
export default {
  components: {
    "v-header": header
  },
};
</script>

<style lang="less" scoped>
.data {
  //   min-height: 100vh;
  background: #f5f6f7;
}
.news {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  &-left {
    width: 73%;
    .datas {
      width: 100%;
      height: auto;
      background: #fff;
      padding-bottom: 20px;
      h1 {
        width: 95%;
        height: 50px;
        line-height: 70px;
        margin: auto;
        margin-bottom: 10px;
        font-size: 20px;
      }
      .box {
        width: 92%;
        height: 25px;
        line-height: 25px;
        margin: auto;
        display: flex;
        background: #f5f6f7;
        padding: 10px;
        .list {
          width: auto;
          color: #000;
          opacity: 0.7;
          margin-right: 25px;
          font-size: 14px;
        }
      }
      .btn {
        width: 95%;
        margin: auto;
        position: relative;
        top: 10px;
        margin-bottom: 10px;
      }
    }
    .content {
      width: 100%;
      min-height: 300px;
      background: #fff;
      margin-top: 10px;
      .con {
        width: 95%;
        margin: auto;
        padding-top: 10px;
      }
    }
  }
  &-right {
    width: 26%;
    // min-height: 100px;
    background: #fff;
    h3 {
      width: 100%;
      height: 50px;
      position: relative;
      text-indent: 20px;
      font-size: 17px;
      border-bottom: 1px solid #f5f6f7;
      display: flex;
      align-items: center;
      &::after {
        position: absolute;
        content: "";
        width: 5px;
        height: 15px;
        background: #3363ff;
        left: 0;
      }
    }
    ul {
      padding-top: 10px;
      padding-bottom: 10px;
      li {
        height: 35px;
        line-height: 35px;
        list-style: none;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .code {
          width: 20px;
          height: 20px;
          line-height: 20px;
          color: #000;
          background: #f3f3f3;
          text-align: center;
          border-radius: 3px;
          margin-left: 15px;
          margin-right: 10px;
          font-size: 11px;
        }
        a {
          text-decoration: none;
          color: #000;
          opacity: 0.8;
          font-size: 14px;
        }
        .code-1 {
          color: #fff;
          background: #ff4141;
        }
        .code-2 {
          color: #fff;
          background: #ff7b41;
        }
        .code-3 {
          color: #fff;
          background: #ffc641;
        }
      }
    }
  }
}
</style>